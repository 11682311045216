import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthServicee } from './auth.service';
import { MessageService } from 'primeng/api';
import { Error406blockedComponent } from '../public-pages/error406blocked/error406blocked.component';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class ErrorHandlerService {
  public errorMessage = '';
  protected addyearDialogRef: DynamicDialogRef;
  private isDialogOpen = false;
  private errorHandled = false;
  constructor(
      private router: Router,
      private auth: AuthServicee,
      private dialogService: DialogService,
      private spinner: NgxSpinnerService
  ) {
  }

  public handleError(error: HttpErrorResponse) {
    if (error.status === 500) {
      this.handle500Error(error);
    } else if (error.status === 401) {
      this.handle401Error(error);
    } else if (error.status === 404) {
      this.handle404Error(error);
    } else if (error.status === 406) {
      this.handle406Error(error);
    } else {
      this.handleOtherError(error);
    }
  }

  private handle500Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    // this.router.navigate(['/public/server-error']);
  }

  private handle404Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    // this.router.navigate(['/public/maintenance']);
  }

  private handle401Error(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    if (error.error.msg !== 'Old Password is incorrect.') {
    this.auth.refreshToken().subscribe(opts => {
      localStorage.setItem('user', JSON.stringify(opts.user));
      localStorage.setItem('accessToken', opts.accessToken);
      localStorage.setItem('idToken', opts.idToken);
      const currentUrl = this.router.url; // Get the current URL with query params
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(currentUrl); // Navigate back to the same route
      });
    }, (error) => {
      localStorage.clear();
      this.router.navigate(['/public/unauthorized']);
    });
  }
  }

  protected handle406Error = (error: HttpErrorResponse): void => {
    this.spinner.hide();
    this.router.navigate(['/public/blocked'], {
      queryParams: { error: error.error.msg }
    });
  }

  private handleOtherError(error: HttpErrorResponse) {
    this.createErrorMessage(error);
    // TODO: this will be fixed later;
  }

  private createErrorMessage(error: HttpErrorResponse) {
    this.errorMessage = error.error.msg ? error.error.msg : error.error.msg;
  }

}
