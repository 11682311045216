import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { languageConstants } from 'src/app/constants/languageConstants';

@Component({
  selector: 'app-error406blocked',
  templateUrl: './error406blocked.component.html',
  styleUrls: ['./error406blocked.component.css']
})
export class Error406blockedComponent implements OnInit, OnChanges {
  language = 'en';
  lan = localStorage.getItem('language') || 'en';
  languageKey = languageConstants[this.lan];
  languageConstants = languageConstants;
  public errorMessage: string | null = null;
  constructor(private router: Router, private route: ActivatedRoute)  {
    const lang = localStorage.getItem('language');
    if(lang) {
      this.language = lang;
      this.languageKey = this.languageConstants[lang]
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.errorMessage = params['error'];
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      localStorage.clear();
    },1500);
  }

  btn_logOut():void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
