import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { MessageService } from 'primeng/api';
import { languageConstants } from 'src/app/constants/languageConstants';

@Component({
  selector: 'logibid-form',
  template: '',
})
export class FormComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  language = 'en';
  languageConstants = languageConstants;
  languageKeys = languageConstants[this.language];
  disableSubmit = false;

  constructor(fs: FormService, private messageService: MessageService) {
    const langu = localStorage.getItem('language');
    if(langu) {
      this.language = langu;
      this.languageKeys = this.languageConstants[langu]
    }
  }

  ngOnInit() {
  }

  submit(data) {
    throw new Error('Not implemented!');
  }

  onSubmit(secondaryForm?) {
    const form = secondaryForm || this.form;
    this.submitted = true;
    // debugger
    if (form.valid && !this.disableSubmit) {
      this.disableSubmit = true;
      this.submit(form.value);
      this.disableSubmit = false;
    } else if (!form.valid) {
      this.disableSubmit = false;
      this.form.markAllAsTouched();
    }
  }

  submittable() {
    this.disableSubmit = false;
    this.submitted = false;
  }
}
